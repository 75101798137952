import React from 'react';
import _ from 'lodash';
import { Button } from 'antd';
import { action, observable } from 'mobx';
import { RenderByPermission, request, SearchListModal } from '../../utils';
import type { BaseData, IEgGridModel, IMainSubStructureModel, MainSubStructureModel, NormalProgrammeParams } from '../../utils';
import HandleModel from './modal/handleModel';
import HandleRecordModel from './modal/handleRecordModel';
import { API } from './api';

interface Statistics {
  noDealCount: string;
  noValidCount: string;
  totalCount: string;
}
export default class Model {
  constructor() {
    // this.getPriceCategory();
    this.pageStore.grid.onQuery();
  }

  public handleStore = new HandleModel({ parent: this });

  public handleRecordStore = new HandleRecordModel({ parent: this });

  @observable
  public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        label: '社群名称',
        field: 'groupName',
      },
      {
        type: 'input',
        label: '用户昵称',
        field: 'nickName',
      },
      {
        type: 'input',
        label: '手机号',
        field: 'mobile',
      },
      {
        type: 'input',
        label: '用户ID',
        field: 'userId',
      },
      {
        type: 'select',
        label: '会员状态',
        field: 'memberStatus',
        data: [
          {
            label: '正常',
            value: '1',
          },
          {
            label: '过期',
            value: '2',
          },
        ],
      },
    ],
  };

  @observable
  public statistics: Statistics = {
    noDealCount: '0',
    noValidCount: '0',
    totalCount: '0',
  };

  /* 处理顶部统计数据 */
  @action
  public setStatistics = (data: Statistics) => {
    this.statistics = data;
    this.pageStore.grid.collectData = [
      {
        name: '总量',
        value: this.statistics.totalCount || 0,
        color: 'blue',
      },
      {
        name: '到期',
        value: this.statistics.noValidCount || 0,
        color: 'blue',
      },
      {
        name: '待处理',
        value: this.statistics.noDealCount || 0,
        color: 'blue',
      },
    ];
  };

  public grid: IMainSubStructureModel = {
    collectData: [
      {
        name: '总量',
        value: this.statistics.totalCount,
        color: 'blue',
      },
      {
        name: '到期',
        value: this.statistics.noValidCount,
        color: 'blue',
      },
      {
        name: '待处理',
        value: this.statistics.noDealCount,
        color: 'blue',
      },
    ],
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          width: 150,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                {row.memberStatus !== 1 &&
                  (row.memberDealStatus === 0 ? (
                    <RenderByPermission permissionId={`${pageId}_23`}>
                      <Button
                        onClick={() => {
                          console.log('handle');
                          this.handleStore.onShow(row);
                        }}
                        type="link"
                      >
                        处理
                      </Button>
                    </RenderByPermission>
                  ) : (
                    <RenderByPermission permissionId={`${pageId}_24`}>
                      <Button
                        onClick={() => {
                          console.log('handleDetail');
                          this.handleRecordStore.onShow(row);
                        }}
                        type="link"
                      >
                        处理记录
                      </Button>
                    </RenderByPermission>
                  ))}
              </div>
            );
          },
        },
        {
          key: 'groupName',
          name: '社群名称',
        },
        {
          key: 'nickName',
          name: '用户',
        },
        {
          key: 'memberName',
          name: '会员',
          // formatter: ({ row }) => this.getPriceCategoryName(row.priceCategory),
        },
        {
          key: 'memberStatus',
          name: '会员状态 ',
          formatter: ({ row }) => {
            if (row.memberStatus === 1) {
              return '正常';
            }
            if (row.memberStatus === 2) {
              return '过期';
            }
            return '';
          },
        },
        {
          key: 'memberDealStatus',
          name: '处理状态',
          formatter: ({ row }) => {
            if (row.memberStatus !== 1) {
              if (row.memberDealStatus === 0) {
                return '未处理';
              }
              if (row.memberDealStatus === 1) {
                return '已处理';
              }
            }
            return '';
          },
        },
      ].map((item) => ({
        resizable: true,
        sortable: false,
        ...item,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showGridOrderNo: true,
    },
    api: {
      onQuery: (params: IEgGridModel['queryParam']) => {
        const { filterParams, pageNum, pageSize } = params;
        const postParams = {
          ...filterParams,
          pageNum,
          pageSize,
        };

        return request<BaseData<any>>({
          url: API.fetchMemberList,
          method: 'POST',
          data: postParams,
        });
      },
      callbackAfterQuery: (_this: MainSubStructureModel) => {
        const { filterParams, pageNum, pageSize } = _this.history;
        const postParams = {
          ...filterParams,
          pageNum,
          pageSize,
        };
        request<{ code: number; msg?: string; data: Statistics }>({
          url: API.fetchMemberStatistics,
          method: 'POST',
          data: postParams,
        })
          .then((res) => {
            if (res.code === 200) {
              this.setStatistics(res.data);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      },
    },
  };

  public pageStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  /* 查询字典相关 */

  // @observable
  // public unitOptions = [];

  // @action
  // public getPriceCategory = async () => {
  //   const req = await request<{ data: any[] }>({
  //     method: 'GET',
  //     url: '/quan/sys/dict/type',
  //     params: { dictType: 'price_category' },
  //   });
  //   this.unitOptions = req.data;
  // };

  // public getPriceCategoryName = (id: string): string => {
  //   let name = '';
  //   this.unitOptions.forEach((item) => {
  //     if (item.dictValue === id) {
  //       name = item.dictName;
  //     }
  //   });
  //   return name;
  // };
}
